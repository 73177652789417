define(['santa-components', 'lodash', 'componentsCore', 'wixappsCore/proxies/mixins/baseProxy'], function (santaComponents, _, componentsCore, baseProxy) {
    'use strict';

    const compDisplayModeMap = {
        'fitWidth': 'fitWidthStrict',
        'fitHeight': 'fitHeightStrict'
    };

    const componentType = 'wysiwyg.viewer.components.WPhoto';


    function getPhotoWidthAndHeight(compDisplayMode, originalStyle, measures) { // eslint-disable-line complexity
        const {width, height, minWidth, minHeight} = originalStyle;
        const originalSize = {
            width: width || minWidth,
            height: height || minHeight
        };

        if (compDisplayMode === 'fitWidth' && _.isNumber(originalSize.width)) {
            return originalSize;
        }
        if (compDisplayMode === 'fitHeight' && _.isNumber(originalSize.height)) {
            return originalSize;
        }
        if (_.isNumber(originalSize.height) && _.isNumber(originalSize.width)) {
            return originalSize;
        }

        // https://jira.wixpress.com/browse/CLNT-7447
        return {
            width: measures.width || 16,
            height: measures.height || 16
        };
    }

    /**
     * @class proxies.mixins.baseImageProxy
     * @property {function(): object} getCompData
     * @extends proxies.mixins.baseProxy
     */

    return {
        mixins: [baseProxy],

        statics: {
            componentType
        },

        getCustomStyle() {
            return {
                position: 'relative'
            };
        },

        renderProxy() {
            const displayMode = this.getCompProp('imageMode') || 'fill';
            const compData = this.getCompData();
            const compDisplayMode = compDisplayModeMap[displayMode] || displayMode;

            const photoProps = this.getChildCompProps(componentType, null, {compData, compProp: {displayMode: compDisplayMode}});
            photoProps.disableImageAutoLayout = true;
            const props = {
                'data-proxy-name': 'Image',
                'data-width': compData.width,
                'data-height': compData.height,
                'data-uri': compData.uri,
                'data-display-mode': compDisplayMode,
                id: `${photoProps.id}_container`,
                style: photoProps.style,
                ref: 'container',
                key: photoProps.id
            };

            photoProps.style = getPhotoWidthAndHeight(compDisplayMode, photoProps.style, this.props.viewProps.getMeasures(photoProps.id), photoProps.id);

            return santaComponents.utils.createReactElement(
                'div',
                props,
                componentsCore.compRegistrar.getCompClass(componentType, true)(photoProps)
            );
        }
    };
});
