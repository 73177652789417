define([
    'prop-types',
    'componentsCore',
    'wixappsCore/core/typesConverter',
    'wixappsCore/proxies/mixins/baseProxy'
], function (PropTypes, componentsCore, /** wixappsCore.typesConverter */typesConverter, baseProxy) {
    'use strict';

    /**
     * @class proxies.ImageButton
     * @extends proxies.mixins.baseProxy
     */
    return {
        mixins: [baseProxy],
        propTypes: {
            viewProps: PropTypes.shape({
                serviceTopology: PropTypes.object.isRequired,
                resolveImageData: PropTypes.func.isRequired,
                packageName: PropTypes.string.isRequired
            })
        },
        renderProxy() {
            const data = this.proxyData;
            const componentType = 'wixapps.integration.components.ImageButton';
            const props = this.getChildCompProps(componentType);
            props.compData = typesConverter.icon(data, this.props.viewProps.resolveImageData, this.props.viewProps.serviceTopology, this.props.viewProps.packageName);

            props.compProp = {
                isSprite: this.getCompProp('isSprite'),
                spriteDirection: this.getCompProp('spriteDirection'),
                startPositionX: this.getCompProp('startPositionX'),
                startPositionY: this.getCompProp('startPositionY')
            };

            //tooltip is being used only in the editor. Moving on to other features. Jira task CLNT-735
            //props.onMouseOver = function showToolTip(){};
            //props.onMouseOut = function hideToolTip(){};

            return componentsCore.compRegistrar.getCompClass(componentType, true)(props);
        }
    };
});
