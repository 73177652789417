define(['componentsCore', 'wixappsCore/core/typesConverter', 'wixappsCore/proxies/mixins/baseProxy'], function (componentsCore, /** wixappsCore.typesConverter */typesConverter, baseProxy) {
    'use strict';

    const componentType = 'wysiwyg.viewer.components.Video';

    /**
     * @class proxies.Video
     * @extends proxies.mixins.baseProxy
     */
    return {
        mixins: [baseProxy],
        statics: {
            componentType
        },
        renderProxy() {
            const data = this.proxyData;
            const props = this.getChildCompProps(componentType);
            props.compData = typesConverter.video(data);
            props.compProp = {
                showControls: this.getCompProp('showControls'),
                autoplay: this.getCompProp('autoplay'),
                loop: this.getCompProp('loop'),
                showinfo: this.getCompProp('showinfo'),
                lightTheme: this.getCompProp('lightTheme')
            };

            return componentsCore.compRegistrar.getCompClass(componentType, true)(props);
        }
    };
});
