define([
    'lodash',
    'wixappsCore/proxies/mixins/baseProxy',
    'wixappsCore/core/typesConverter',
    'wixappsCore/util/localizer',
    'wixappsCore/util/richTextUtils',
    'reactDOM'
],
function (_, baseProxy, typesConverter, localizer, richTextUtils, ReactDOM) {
    'use strict';

    const unescapedElementsRegex = new RegExp(_.escape('<br/?>'), 'gi');

    function getLinkDataByQuery(pagesDataItems, data, query) {
        const link = _.find(data.links, {linkId: query});
        return link ? typesConverter.link(link, pagesDataItems) : null;
    }

    /**
         * @class proxies.mixins.textProxy
         * @extends proxies.mixins.baseProxy
         */

    return {
        mixins: [baseProxy],
        statics: {
            prefix: {type: 'compProp', defaultValue: ''},
            postfix: {type: 'compProp', defaultValue: ''},
            disableLinks: {type: 'compProp', defaultValue: false},
            lineThrough: {type: 'compProp', defaultValue: false},
            underline: {type: 'compProp', defaultValue: false},
            singleLine: {type: 'compProp', defaultValue: false},
            color: {type: 'compProp', defaultValue: null},
            backgroundColor: {type: 'compProp', defaultValue: null},
            noWrap: {type: 'compProp', defaultValue: false},
            bold: {type: 'compProp', defaultValue: false},
            italic: {type: 'compProp', defaultValue: false},
            lineHeight: {type: 'compProp', defaultValue: null},
            fontFamily: {type: 'compProp', defaultValue: null},
            fontSize: {type: 'compProp', defaultValue: null},
            showTooltip: {type: 'compProp', defaultValue: false}
        },

        useSkinInsteadOfStyles: true,

        getInitialState() {
            return {showToolTip: false};
        },

        componentDidMount() {
            const domNode = ReactDOM.findDOMNode(this);
            const shouldShowToolTop = !!(this.getCompProp('singleLine') && this.getCompProp('showTooltip') && domNode.scrollWidth > domNode.offsetWidth);
            if (shouldShowToolTop !== this.state.showToolTip) {
                /*eslint react/no-did-mount-set-state:0*/
                this.setState({showToolTip: shouldShowToolTop});
            }
        },

        createFormattedText(data, defaultElementTag) {
            if (data._type === 'wix:RichText' || data._type === 'wix:MediaRichText') {
                const partVersion = this.props.viewProps.getPartDefinition().version;
                return richTextUtils.getDataWithDefaultStyleForRichText(this.getCompProp, data, defaultElementTag, partVersion);
            }

            if (!_.isString(data)) {
                data = _.isNumber(data) ? data.toString() : '';
            }

            if (this.props.viewProps.isExperimentOpen('sv_limitAuthorLength')) {
                const maxChars = this.getCompProp('max-chars');
                if (maxChars) {
                    data = data.substring(0, maxChars);
                }
            }

            const localizationBundle = this.props.viewProps.getLocalizationBundle();
            const translatedText = localizer.localize(data, localizationBundle);
            const escapedText = translatedText.replace(unescapedElementsRegex, _.unescape);
            const wrappedData = _.compact(['<hatul>', this.getCompProp('prefix'), escapedText, this.getCompProp('postfix'), '</hatul>']).join('');
            return richTextUtils.getDataWithDefaultStyleForString(this.getCompProp, wrappedData, defaultElementTag);
        },

        getDataByQuery(query) {
            return getLinkDataByQuery(this.props.viewProps.linkRenderInfo.pagesDataItemsMap, this.proxyData, query);
        },

        getRichTextChildCompProps(componentType, transformSkinPropertiesFunc, compData) {
            const props = this.getChildCompProps(componentType, transformSkinPropertiesFunc, {compData});
            props.style = props.style || {};
            props.style.whiteSpace = this.getCompProp('noWrap') ? 'nowrap' : 'normal';

            // looks like it is being used only in non richtext types
            if (this.state.showToolTip) {
                props.title = (this.getCompProp('prefix') || `${this.proxyData}${this.getCompProp('postfix')}` || '').replace(/\s+/mgi, ' ').replace(/^\s*/, '').replace(/\s*$/, '');
            }

            props.noAutoLinkGeneration = true;

            return props;
        }
    };
}
);
