define([
    'coreUtils'
], function (coreUtils) {
    'use strict';

    const {createGlobalSantaTypesFetcher} = coreUtils.propsSelectorsUtils;

    const linkRenderInfo = createGlobalSantaTypesFetcher(function (state) {
        return coreUtils.wixUrlParser.utils.getResolvedSiteData(state.siteData);
    }, 'linkRenderInfo');

    return {
        linkRenderInfo
    };
});
