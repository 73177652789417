define(['componentsCore', 'wixappsCore/core/typesConverter', 'wixappsCore/proxies/mixins/inputProxy'], function (componentsCore, /** wixappsCore.typesConverter */typesConverter, inputProxy) {
    'use strict';

    /**
     * @class proxies.ErasableTextInput
     * @extends proxies.mixins.inputProxy
     */
    return {
        mixins: [inputProxy],
        renderProxy() {
            const data = this.proxyData;
            const componentType = 'wysiwyg.viewer.components.inputs.ErasableTextInput';
            const props = this.getChildCompProps(componentType);
            props.compData = typesConverter.text(data);
            props.compProp = {
                label: this.getCompProp('label'),
                placeholder: this.getCompProp('placeholder')
            };
            props.message = this.getCompProp('message');
            props.isValid = this.getCompProp('isValid');
            const self = this;
            props.onChange = function (e, domID) {
                self.setData(e.target.value);
                e.type = 'inputChanged'; // eslint-disable-line santa/no-side-effects
                self.handleViewEvent(e, domID);
            };
            props.onErase = function () {
                self.setData('');
            };

            return componentsCore.compRegistrar.getCompClass(componentType, true)(props);
        }
    };
});
