define(['santa-components', 'componentsCore'], function (santaComponents, componentsCore) {
    'use strict';

    const SpriteState = {
        Default: 0,
        Hover: 1,
        Click: 2
    };

    /**
     * @class components.ImageButton
     * @extends {core.skinBasedComp}
     */
    const wixAppsImageButton = {
        displayName: 'ImageButton',

        mixins: [componentsCore.mixins.skinBasedComp],

        getInitialState() {
            return {currentPositionX: this.props.compProp.startPositionX, currentPositionY: this.props.compProp.startPositionY};
        },

        setSpriteState(offset) {
            const compProp = this.props.compProp;
            const compData = this.props.compData;

            switch (compProp.spriteDirection) {
                case 'none':
                    break;
                case 'horizontal':
                    this.setState({currentPositionX: compProp.startPositionX - offset * compData.width}); // eslint-disable-line no-mixed-operators
                    break;
                default:
                    this.setState({currentPositionY: compProp.startPositionY - offset * compData.height}); // eslint-disable-line no-mixed-operators
                    break;
            }
        },

        onMouseOver() {
            this.setSpriteState(SpriteState.Hover);
            if (this.props.onMouseOver) {
                this.props.onMouseOver();
            }
        },

        onMouseDown() {
            this.setSpriteState(SpriteState.Click);
        },

        onMouseUp() {
            this.setSpriteState(SpriteState.Hover);
        },

        onMouseOut() {
            this.setSpriteState(SpriteState.Default);
            if (this.props.onMouseOut) {
                this.props.onMouseOut();
            }
        },

        getSpriteProperties(compData) {
            const styles = {
                backgroundImage: `url(${compData.url})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: `${this.state.currentPositionX}px ${this.state.currentPositionY}px`,
                width: compData.width,
                height: compData.height,
                display: 'inline-block'
            };

            return {
                '': {
                    style: {
                        width: compData.width,
                        height: compData.height
                    },
                    children: santaComponents.utils.createReactElement('span', {style: styles}),
                    onMouseOver: this.onMouseOver,
                    onMouseOut: this.onMouseOut,
                    onMouseDown: this.onMouseDown,
                    onMouseUp: this.onMouseUp
                }
            };
        },

        getImageProperties(compData) {
            return {
                '': {
                    style: {
                        width: compData.width,
                        height: compData.height
                    },
                    children: santaComponents.utils.createReactElement(
                        'img',
                        {src: compData.url, width: compData.width, height: compData.height, title: compData.title}
                    ),
                    onMouseOver: this.props.onMouseOver,
                    onMouseOut: this.props.onMouseOut
                }
            };
        },

        getSkinProperties() {
            const compData = this.props.compData;

            return this.props.compProp.isSprite ?
                this.getSpriteProperties(compData) :
                this.getImageProperties(compData);
        }
    };

    componentsCore.compRegistrar.register('wixapps.integration.components.ImageButton', wixAppsImageButton);

    return wixAppsImageButton;
});
