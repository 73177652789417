define(['coreUtils', 'wixappsCore/proxies/mixins/baseProxy'],
    function (coreUtils, baseProxy) {
        'use strict';

        function isVar(dataPath) {
            return coreUtils.stringUtils.startsWith(dataPath, '$');
        }

        /**
         * @class proxies.mixins.inputProxy
         * @extends {proxies.mixins.baseProxy}
         */
        return {
            mixins: [baseProxy],
            setData(value, propertyPath) {
                let dataPath = this.getViewDefProp('data') || 'this';
                if (propertyPath) {
                    dataPath += `.${propertyPath}`;
                }
                const setFunc = isVar(dataPath) ? this.props.viewProps.setVar : this.props.viewProps.setDataByPath;
                setFunc(this.contextPath, dataPath, value);
            }
        };
    });