define([
    'prop-types',
    'componentsCore',
    'wixappsCore/core/typesConverter',
    'wixappsCore/proxies/mixins/baseProxy'
], function (PropTypes, componentsCore, /** wixappsCore.typesConverter */typesConverter, baseProxy) {
    'use strict';

    /**
     * @class proxies.Icon
     * @extends proxies.mixins.baseProxy
     */
    return {
        mixins: [baseProxy],
        propTypes: {
            viewProps: PropTypes.shape({
                serviceTopology: PropTypes.object.isRequired,
                resolveImageData: PropTypes.func.isRequired,
                packageName: PropTypes.string.isRequired
            })
        },
        renderProxy() {
            const data = this.proxyData;
            const componentType = 'wixapps.integration.components.Icon';
            const props = this.getChildCompProps(componentType);
            props.compData = typesConverter.icon(data, this.props.viewProps.resolveImageData, this.props.viewProps.serviceTopology, this.props.viewProps.packageName);

            return componentsCore.compRegistrar.getCompClass(componentType, true)(props);
        }
    };
});
