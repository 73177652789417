define([
    'lodash',
    'prop-types',
    'componentsCore',
    'santa-components',
    'wixappsCore/proxies/mixins/textProxy',
    'wixappsCore/core/typesConverter',
    'wixappsCore/core/proxySantaTypesDefinitions',
    'wixappsCore/util/textClipper',
    'coreUtils'
], function (_, PropTypes, componentsCore, santaComponents, textProxy, /** wixappsCore.typesConverter */typesConverter, proxySantaTypesDefinitions, textClipper, coreUtils) {
    'use strict';


    function lineHeightToFloat(lineHeight) {
        let ret = parseFloat(lineHeight);
        if (isNaN(ret) && _.includes(['normal', 'initial', 'inherit'], lineHeight)) {
            ret = 1.2;
        }
        return ret;
    }

    function getMinHeight(formattedText) {
        const minLines = this.getCompProp('minLines') || this.getCompProp('singleLine') && 1; // eslint-disable-line no-mixed-operators
        if (!minLines) {
            return 0;
        }

        const fontClass = coreUtils.fontUtils.getFontClassName(formattedText);
        const fontStr = fontClass && this.props.viewProps.fontsMap[fontClass.split('_')[1]];
        const font = coreUtils.fontUtils.parseFontStr(fontStr);
        let lineHeight = 1.25; // this is the default value (=> line-height:normal)

        // can only be overridden by explicitly setting line-height on the comp
        if (this.getCompProp('line-height')) {
            lineHeight = lineHeightToFloat(this.getCompProp('line-height'));
        }

        return parseInt(font.size, 10) * lineHeight * minLines;
    }

    /**
     * @class proxies.ClippedParagraph
     * @extends proxies.mixins.textProxy
     */
    return {
        mixins: [textProxy],
        propsTypes: {
            viewProps: PropTypes.shape({
                linkRenderInfo: proxySantaTypesDefinitions.linkRenderInfo.isRequired,
                fontsMap: santaComponents.santaTypesDefinitions.Fonts.fontsMap.isRequired
            }
            )
        },
        renderProxy() {
            const data = this.proxyData;

            let formattedText = this.createFormattedText(data);

            const minHeight = getMinHeight.call(this, formattedText);

            const transformSkinProperties = function (refData) {
                refData[''].style = refData[''].style || {};
                refData[''].style.overflow = 'hidden';
                if (minHeight) {
                    refData[''].style['min-height'] = minHeight;
                }

                return _.set(refData, ['richTextContainer', 'style', 'height'], 'initial');
            };

            const componentType = 'wysiwyg.viewer.components.WRichText';

            const maxChars = this.getCompProp('max-chars') || 150;
            const removeAnchors = this.getCompProp('remove-anchors') || false;
            if (removeAnchors) {
                formattedText = formattedText.replace(/(<a[^>]*>)/g, '').replace(/(<\/a>)/g, '');
            }

            formattedText = textClipper.clipText(formattedText, maxChars);

            const compData = typesConverter.richText(formattedText, data.links, this.props.viewProps.linkRenderInfo.pagesDataItemsMap);
            const props = this.getRichTextChildCompProps(componentType, transformSkinProperties, compData);

            return componentsCore.compRegistrar.getCompClass(componentType, true)(props);
        }
    };
});
