define([
    'santa-components',
    'lodash',
    'prop-types',
    'create-react-class',
    'wixappsCore/util/viewsUtils',
    'wixappsCore/proxies/mixins/baseProxy',
    'wixappsCore/proxies/mixins/baseCompositeProxy',
    'wixappsCore/core/stylesheetRenderer',
    'wixappsCore/util/typeNameResolver',
    'wixappsCore/util/wrappedProxyStyles'
],
function (
    santaComponents,
    _,
    PropTypes,
    createReactClass,
    /** wixappsCore.viewsUtils */ viewsUtils,
    baseProxy,
    baseCompositeProxy,
    /** wixappsCore.stylesheetRenderer */ stylesheetRenderer,
    /** wixappsCore.typeNameResolver */typeNameResolver,
    /** wixappsCore.wrappedProxyStyles */ wrappedProxyStyles
) {
    'use strict';

    function getChildProps(viewDef, viewName, forType) {
        const props = this.getChildProxyProps(viewDef);
        props.viewDef = viewDef;
        props.viewName = viewName;
        props.forType = forType;
        props.viewId = viewsUtils.sanitizeCompId(`${viewName}_${forType}_${this.props.formatName}`);
        props.viewContainerId = viewsUtils.sanitizeCompId(`${props.viewId}_${this.contextPath}`);
        props.parentContextPath = this.innerContextPath;
        return props;
    }

    function renderStylesheet(stylesheet, compId, viewId) {
        const styleData = stylesheetRenderer.render(stylesheet, compId, viewId);
        return santaComponents.utils.createReactElement('style', {
            type: 'text/css',
            key: `${compId}.${viewId}`,
            dangerouslySetInnerHTML: {
                __html: styleData || ''
            }
        });
    }

    const wixappsStyleSheet = createReactClass({
        displayName: 'WixappsStyleSheet',
        mixins: [baseProxy],
        propTypes: {
            viewContainerId: PropTypes.string,
            viewProps: PropTypes.shape({
                getViewDef: PropTypes.func.isRequired,
                compId: PropTypes.string.isRequired
            })
        },

        renderProxy() {
            return renderStylesheet(this.getViewDefProp('stylesheet'), this.props.viewProps.compId, this.props.viewContainerId);
        }
    });

    function getContainerProps(props, childViewStyleDef, proxyStyle) {
        const containerProps = this.getChildCompProps();
        containerProps.id = props.viewContainerId;
        containerProps.ref = 'viewWrapper';
        containerProps.style = _.merge({}, childViewStyleDef, proxyStyle);
        containerProps.className += ` ${wrappedProxyStyles.getWrapperCssClass(containerProps.style)}`;
        return containerProps;
    }

    /**
     * @class proxies.View
     * @extends proxies.mixins.baseCompositeProxy
     */
    return {
        mixins: [baseCompositeProxy],
        propTypes: {
            formatName: PropTypes.string.isRequired,
            viewName: PropTypes.string,
            viewContainerId: PropTypes.string,
            viewContextMap: PropTypes.shape({
                hasContext: PropTypes.func.isRequired
            }),
            viewProps: PropTypes.shape({
                getViewDef: PropTypes.func.isRequired,
                compId: PropTypes.string.isRequired
            })
        },
        renderProxy() {
            const viewName = this.getCompProp('name') || this.props.viewName;
            const data = this.proxyData;
            const forType = typeNameResolver.getDataItemTypeName(data);
            const viewDef = this.props.viewProps.getViewDef(viewName, forType, this.props.formatName);

            if (!viewDef) {
                throw new Error(`ViewProxy:: Cannot find view definition for viewName [${viewName}] typeName [${forType}] format [${this.props.formatName}]`);
            }

            const viewData = this.getViewDefProp('data') || 'this';
            const viewVars = _.merge({}, this.getCompProp('vars'), this.getViewDefProp('vars', viewDef));

            // if we haven't created a context already or the context we created was deleted from the context map - create a new one
            if (!this.innerContextPath || !this.props.viewContextMap.hasContext(this.innerContextPath)) {
                this.innerContextPath = this.createContext(this.contextPath, [viewData], {view: viewVars}, {}, {});
            }
            const props = getChildProps.call(this, viewDef, viewName, forType);
            const proxyStyle = this.getProxyStyle();
            const childViewStyleDef = this.getStyleDef(viewDef, this.innerContextPath);
            const containerProps = getContainerProps.call(this, props, childViewStyleDef, proxyStyle);

            const childProxyStyle = wrappedProxyStyles.getProxyStyles(containerProps.style);
            if (_.has(containerProps.style, 'height')) {
                childProxyStyle.height = childProxyStyle.width = '100%';
            }

            const childProxy = this.renderChildProxy(viewDef, 'child', childProxyStyle, props);

            //var stylesheet = renderStylesheet(this.getViewDefProp("stylesheet", viewDef), this.props.compId, viewContainerId);

            const stylesheetProps = getChildProps.call(this, viewDef, viewName, forType);
            stylesheetProps.key = 'stylesheet';
            const stylesheet = santaComponents.utils.createReactElement(wixappsStyleSheet, stylesheetProps);

            return santaComponents.utils.createReactElement('div', containerProps, stylesheet, childProxy);
        }
    };
});
