define([
    'lodash',
    'prop-types',
    'wixappsCore/core/proxySantaTypesDefinitions',
    'componentsCore',
    'wixappsCore/proxies/mixins/textProxy',
    'wixappsCore/core/typesConverter'
], function (_, PropTypes, proxySantaTypesDefinitions, componentsCore, textProxy, /** wixappsCore.typesConverter */typesConverter) {
    'use strict';

    const COMPONENT_TYPE = 'wysiwyg.viewer.components.WRichText';

    function transformSkinProperties(refData) {
        const skinProperties = {
            richTextContainer: {
                style: {height: 'initial'}
            }
        };

        return _.defaultsDeep(skinProperties, refData);
    }

    /**
     * @class proxies.Label
     * @extends proxies.mixins.textProxy
     */
    return {
        mixins: [textProxy],
        statics: {
            componentType: COMPONENT_TYPE
        },
        propsTypes: {
            viewProps: PropTypes.shape({
                linkRenderInfo: proxySantaTypesDefinitions.linkRenderInfo.isRequired
            }
            )
        },
        renderProxy() {
            const data = this.proxyData;

            const elementTag = this.getCompProp('elementTag');
            const formattedText = this.createFormattedText(data, elementTag);

            const compData = typesConverter.richText(formattedText, data.links, this.props.viewProps.linkRenderInfo.pagesDataItemsMap);
            const props = this.getRichTextChildCompProps(COMPONENT_TYPE, transformSkinProperties, compData);


            return componentsCore.compRegistrar.getCompClass(COMPONENT_TYPE, true)(props);
        }
    };
});
