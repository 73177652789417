define(['lodash', 'componentsCore', 'wixappsCore/proxies/mixins/baseProxy', 'wixappsCore/core/typesConverter', 'wixappsCore/util/localizer'], function (_, componentsCore, baseProxy, /** wixappsCore.typesConverter */typesConverter, localizer) {
    'use strict';

    const componentType = 'wysiwyg.viewer.components.SiteButton';

    return {
        mixins: [baseProxy],

        statics: {
            componentType
        },

        getCustomStyle() {
            let style = {position: 'relative'};
            if (this.getAdditionalButtonStyle) {
                style = _.merge(style, this.getAdditionalButtonStyle());
            }

            return style;
        },

        renderProxy() {
            const compData = typesConverter.linkableButton(this.proxyData, this.props.viewProps.linkRenderInfo.pagesDataItemsMap);
            let label = this.getCompProp('label');
            if (_.isUndefined(label)) {
                label = compData.label;
            }
            compData.label = label.toString() ? localizer.localize(label, this.props.viewProps.getLocalizationBundle()) : this.getDefaultLabel();
            compData.noAutoLinkGeneration = true;

            const props = this.getChildCompProps(componentType, this.transformSkinProperties, {
                compData,
                compProp: {
                    align: this.getCompProp('align') || 'center',
                    margin: this.getCompProp('margin') || 0,
                    padding: this.getCompProp('labelPadding') || ''
                },
                shouldUseFlex: false,
                noAutoLinkGeneration: true
            });

            return componentsCore.compRegistrar.getCompClass(componentType, true)(props);
        }
    };
});
