define(['santa-components', 'wixappsCore/proxies/mixins/baseProxy'], function (santaComponents, baseProxy) {
    'use strict';

    /**
     * @class proxies.spacers.inlineSpacer
     * @extends proxies.mixins.baseProxy
     * @property {proxy.properties} props
     */
    return {
        mixins: [baseProxy],
        getCustomStyle() {
            const size = this.getCompProp('size');
            return {
                'wordSpacing': size,
                display: 'inline'
            };
        },
        renderProxy() {
            const childCompProps = this.getChildCompProps();

            return santaComponents.utils.createReactElement('div', childCompProps, ' ');
        }
    };
});

