define([
    'prop-types',
    'lodash',
    'wixappsCore/proxies/mixins/zoomProxy'
], function (PropTypes, _, zoomProxy) {
    'use strict';

    /**
     * @class proxies.ZoomLink
     * @extends proxies.mixins.zoomProxy
     */
    return {
        mixins: [zoomProxy],

        propTypes: {
            viewProps: PropTypes.shape({
                getPartDefinition: PropTypes.func.isRequired,
                getPartData: PropTypes.func.isRequired,
                getNormalizedDataPath: PropTypes.func.isRequired,
                getAppPartZoomUrl: PropTypes.func.isRequired,
                descriptor: PropTypes.object.isRequired
            })
        },

        getCustomProps() {
            const partDef = this.props.viewProps.getPartDefinition();
            const zoomPartDef = _.find(this.props.viewProps.descriptor.parts, {id: partDef.zoomPartName[0]});
            const partData = this.props.viewProps.getPartData();
            const permaLinkId = zoomPartDef.zoomParams.urlIdPrefix + partData.appInnerID;

            const props = {};
            props.href = this.props.viewProps.getAppPartZoomUrl(permaLinkId, this.proxyData._iid || this.proxyData.id, this.proxyData.title);


            props.pathToItems = this.props.viewProps.getNormalizedDataPath(this.contextPath, this.getCompProp('listExpression')).join('.');
            return props;
        }
    };
});
