define(['lodash', 'wixappsCore/proxies/mixins/baseCompositeProxy'], function (_, baseCompositeProxy) {
    'use strict';

    const spacerTypesMap = {
        vertical: 'VSpacer',
        horizontal: 'HSpacer'
    };
    const spacerPropsMap = {
        both: 'spacer',
        before: 'spacerBefore',
        after: 'spacerAfter'
    };

    function createSpacer(type, id) {
        return {
            id,
            comp: {
                name: type,
                size: '*'
            }
        };
    }

    function renderFlexSpacer(childDef, orientation, position, i) {
        orientation = orientation || 'vertical';
        const style = childDef.layout || {};

        if (style[spacerPropsMap[position]] === '*' || style[spacerPropsMap.both] === '*') {
            const spacerType = spacerTypesMap[orientation];
            const spacerId = `${childDef.id}_spacer_${position}`;
            const spacerViewDef = createSpacer(spacerType, spacerId);

            const key = `spacer_${position}_${i}`;

            return this.renderChildProxy(spacerViewDef, key);
        }

        return null;
    }

    function renderFlexSpacerBefore(childDef, orientation, i) {
        return renderFlexSpacer.call(this, childDef, orientation, 'before', i);
    }

    function renderFlexSpacerAfter(childDef, orientation, i) {
        return renderFlexSpacer.call(this, childDef, orientation, 'after', i);
    }

    function isFlexy(value) {
        return !(value === 'none' || value === '0' || value === 0 || value === '' || value === null || _.isUndefined(value));
    }

    function hasAnyFlexy(childrenDefinitions) {
        return _.some(childrenDefinitions, function (childDef) { // eslint-disable-line complexity
            const style = this.getStyleDef(childDef);
            if (style[spacerPropsMap.before] === '*' ||
              style[spacerPropsMap.after] === '*' ||
              style[spacerPropsMap.both] === '*' ||
              isFlexy(style.boxFlex) ||
              isFlexy(style.flex) || // eslint-disable-line no-mixed-operators
              childDef.comp && childDef.comp.size === '*') { // eslint-disable-line no-mixed-operators
                return true;
            }
        }.bind(this));
    }

    /**
     *
     * @param {string} pack
     * @param {string} direction
     * @param {string} orientation
     * @returns {string}
     */
    function fixPackByDirection(pack, direction, orientation) {
        if (direction === 'rtl' && orientation === 'horizontal') {
            const flipPack = {
                start: 'end',
                end: 'start'
            };
            pack = flipPack[pack] || pack;
        }
        return pack;
    }

    /**
     * @class proxies.mixins.boxProxy
     * @extends proxies.mixins.baseCompositeProxy
     * @property {proxy.properties} props
     * @property {function: ReactComponent} getReactClass
     * @property {function: string} getChildrenOrientation
     */
    return {
        mixins: [baseCompositeProxy],

        getCustomStyle() {
            const childrenDefinitions = this.getCompProp('items');
            const flexy = hasAnyFlexy.call(this, childrenDefinitions);
            const pack = this.getCompProp('pack');
            const boxAlign = this.getCompProp('box-align');
            const childOrientation = this.getChildrenOrientation();
            const isHorizontal = childOrientation === 'horizontal';
            const isDisplayFlex = flexy || pack || boxAlign || isHorizontal;
            const style = {
                position: 'relative',
                display: isDisplayFlex ? 'box' : 'block',
                'box-orient': childOrientation
            };

            if (boxAlign) {
                style['box-align'] = boxAlign;
            }

            if (pack) {
                style['justify-content'] = fixPackByDirection(pack, this.getVar('partDirection'), childOrientation);
            }

            return style;
        },

        renderProxy() {
            const childrenDefinitions = this.getCompProp('items');
            const childOrientation = this.getChildrenOrientation();

            let children = _.map(childrenDefinitions, function (childDef, i) {
                const spacerBefore = renderFlexSpacerBefore.call(this, childDef, childOrientation, i);
                const childProxy = this.renderChildProxy(childDef, i);
                const spacerAfter = renderFlexSpacerAfter.call(this, childDef, childOrientation, i);

                return _.compact([spacerBefore, childProxy, spacerAfter]);
            }.bind(this));

            children = _.flattenDeep(children);

            const componentName = this.getComponentName && this.getComponentName();
            let props = this.getChildCompProps(componentName, this.transformSkinProperties);
            if (childOrientation === 'vertical') {
                props.className = props.className || '';
                props.className += ' flex_vbox';
            }

            if (this.getCustomProps) {
                props = _.assign(props, this.getCustomProps());
            }

            const itemScope = this.getCompProp('item-scope');
            if (itemScope) {
                props.itemScope = itemScope;
            }
            const itemType = this.getCompProp('item-type');
            if (itemType) {
                props.itemType = itemType;
            }


            const reactFactory = this.getReactClass();
            return reactFactory(props, children);
        }
    };
});
