define([
    'santa-components',
    'lodash',
    'react',
    'prop-types',
    'create-react-class',
    'wixappsCore/proxies/mixins/boxProxy'
], function (santaComponents, _, React, PropTypes, createReactClass, boxProxy) {
    'use strict';

    const anchorInPositionAbsoluteWithSiblingsClass = createReactClass({
        displayName: 'AnchorInPositionAbsoluteWithSiblings',
        propTypes: {
            href: PropTypes.string,
            style: PropTypes.object,
            children: PropTypes.node
        },

        onClick(event) {
            event.target = this.refs.a; // eslint-disable-line santa/no-side-effects
        },

        render() {
            const props = _.omit(this.props, ['style', 'href']);
            // anchor doesn't get the click so the div catches it and change the target
            // special thanks to Avi Marcus
            props.onClick = this.onClick;
            // set the cursor on the div to pointer because the anchor doesn't get it. stupid anchor.
            props.style = _.merge({}, this.props.style, {cursor: 'pointer'});

            return santaComponents.utils.createReactElement('div', props, santaComponents.utils.createReactElement('a', {
                ref: 'a',
                href: this.props.href,
                'data-page-item-context': props.pathToItems,
                target: props.target,
                style: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0
                }}), props.children);
        }
    });
    const anchorInPositionAbsoluteWithSiblings = React.createFactory(anchorInPositionAbsoluteWithSiblingsClass);

    /**
     * @class proxies.mixins.zoomProxy
     * @extends proxies.mixins.boxProxy
     * @property {proxy.properties} props
     */
    return {
        mixins: [boxProxy],
        getChildrenOrientation() {
            return this.getCompProp('orientation') || 'vertical';
        },

        getReactClass() {
            return this.getCustomZoomReactClass ? this.getCustomZoomReactClass() : anchorInPositionAbsoluteWithSiblings;
        }
    };
});
