define(['lodash', 'coreUtils', 'wixappsCore/util/richTextDefaultStyles', 'wixappsCore/util/styleMapping'],
    function (_, coreUtils, richTextDefaultStyles, styleMapping) {
        'use strict';

        const fontUtils = coreUtils.fontUtils;

        function cleanLinkTags(data) {
        // removes all <a></a> tags
            return data.replace(/<a\b[^>]*>(.*?)<\/a>/ig, '$1');
        }

        function getStyle(styleName) {
            return styleMapping.styleToFontClass(styleName);
        }

        function renderAttributes(attributes) {
            const ret = _.map(attributes, function (attr) {
                if (_.includes(attr.value, '"')) {
                    return `${attr.name}='${attr.value}'`;
                }
                return `${attr.name}="${attr.value}"`;
            }).join(' ');

            return ret.length ? ` ${ret}` : ret;
        }

        function getOpening(tags) {
            return _.map(tags, function (tag) {
                return `<${tag.name}${renderAttributes(tag.attributes)}>`;
            }).join('');
        }

        function getClosing(tags) {
            return _.map(tags, function (tag) {
                return `</${tag.name}>`;
            }).reverse().join('');
        }

        function mergeElements(ret) {
            return _(ret)
                .groupBy('name')
                .map(function (elements, elementName) {
                    const attributes = _(elements)
                        .map('attributes')
                        .flattenDeep()
                        .compact()
                        .groupBy('name')
                        .map(function (value, attribute) {
                            return {
                                name: attribute,
                                value: _.map(value, 'value').join(' ')
                            };
                        }).value();
                    return {
                        name: elementName,
                        attributes
                    };
                }).value();
        }

        function translateHatul(defaultTagName, attributes, getCompProp, additionalHatulStyle) { // eslint-disable-line complexity
            const attrObj = _.reduce(attributes, function (result, attr) {
                result[attr.name] = attr.value;
                return result;
            }, {});

            attrObj.class = getStyle(getCompProp('style') || 'Body M');
            if (getCompProp('singleLine')) {
                attrObj.class += ' singleLine';
            }

            const color = getCompProp('color');
            if (color) {
                if (_.head(color) !== '#') {
                    attrObj.class += ` ${color}`;
                } else {
                    attrObj.style = attrObj.style || '';
                    attrObj.style = `color:${color};${attrObj.style}`;
                }
            }

            const viewDefLineHeight = getCompProp('line-height');
            if (viewDefLineHeight) {
                attrObj.style = attrObj.style || '';
                if (!/line-height:/.test(attrObj.style)) {
                    attrObj.style = `line-height:${viewDefLineHeight}em;${attrObj.style}`;
                }
            }

            const fontFamily = getCompProp('fontFamily');
            if (fontFamily) {
                attrObj.style = attrObj.style || '';
                if (!/font-family:/.test(attrObj.style)) {
                    attrObj.style = `font-family:${fontUtils.getFontFamilyWithFallbacks(fontFamily)};${attrObj.style}`;
                }
            }

            const fontSize = getCompProp('fontSize');
            if (fontSize) {
                attrObj.style = attrObj.style || '';
                if (!/font-size:/.test(attrObj.style)) {
                    attrObj.style = `font-size:${fontSize}px;${attrObj.style}`;
                }
            }

            if (additionalHatulStyle) {
                attrObj.style = attrObj.style || '';
                attrObj.style = `${additionalHatulStyle};${attrObj.style}`;
            }

            const ret = [
                {
                    name: defaultTagName || 'p',
                    attributes: _.reduce(attrObj, function (result, value, key) {
                        result.push({name: key, value});
                        return result;
                    }, [])
                }
            ];

            const properties = ['backgroundColor', 'bold', 'italic', 'lineThrough', 'underline'];

            _.forEach(properties, function (compProp) {
                const compPropValue = getCompProp(compProp);
                if (!_.isNil(compPropValue)) {
                    ret.push(richTextDefaultStyles[compProp](compPropValue));
                }
            });

            return mergeElements(ret);
        }

        function shouldMigrate(data) {
            return data._type === 'wix:RichText' && _.get(data, 'version', 0) < 2;
        }

        function shouldIgnoreHatulTags(data, partVersion) {
            return data._type === 'wix:RichText' && partVersion === '2.0' && _.get(data, 'version', 0) >= 2;
        }

        function migrateText(text) {
            const stack = [];
            const output = [];
            coreUtils.htmlParser(text, {
                start(tagName, attributes, isSingleton) {
                    const tags = [
                        {
                            name: !isSingleton && stack.length === 0 ? 'hatul' : tagName,
                            attributes
                        }
                    ];
                    if (!isSingleton) {
                        stack.push(getClosing(tags));
                    }
                    output.push(getOpening(tags));
                },
                end() {
                    output.push(stack.pop());
                },
                chars(txt) {
                    if (stack.length === 0) {
                        output.push('<hatul>');
                        output.push(txt);
                        output.push('</hatul>');
                    } else {
                        output.push(txt);
                    }
                }
            });

            return output.join('');
        }

        function getDataWithDefaultStyleForRichText(getCompProp, data, defaultElementTag, partVersion, additionalHatulStyle) {
            return getDataWithDefaultStyle(getCompProp, data.text, defaultElementTag, shouldMigrate(data), shouldIgnoreHatulTags(data, partVersion), additionalHatulStyle);
        }

        function getDataWithDefaultStyleForString(getCompProp, data, defaultElementTag) {
            return getDataWithDefaultStyle(getCompProp, data, defaultElementTag, false, false);
        }

        function getDataWithDefaultStyle(getCompProp, rawText, defaultElementTag, shouldMig, ignoreHatulTags, additionalHatulStyle) {
            const text = getCompProp('disableLinks') ? cleanLinkTags(rawText) : rawText;
            const migratedText = shouldMig ? migrateText(text) : text;

            const isSingleLine = getCompProp('singleLine');
            let stopCollecting = false;
            const stack = [];
            let output = [];
            let hasRealText = false;
            coreUtils.htmlParser(migratedText, {
                start(tagName, attributes, isSingleton) { // eslint-disable-line complexity
                    if (stopCollecting) {
                        return;
                    }
                    if (isSingleLine && (tagName === 'img' || tagName === 'br')) {
                        return;
                    }
                    const isRootElement = stack.length === 0;
                    let tags;
                    if (tagName === 'hatul') {
                        if (!isRootElement && ignoreHatulTags) {
                            tags = [{
                                name: 'span',
                                attributes: []
                            }];
                        } else {
                            tags = translateHatul(defaultElementTag, attributes, getCompProp, additionalHatulStyle);
                        }
                    } else {
                        tags = [{
                            name: tagName,
                            attributes
                        }];
                    }
                    if (!isSingleton) {
                        stack.push(getClosing(tags));
                    }
                    output.push(getOpening(tags));
                },
                end() {
                    if (stopCollecting) {
                        return;
                    }
                    output.push(stack.pop());
                    if (stack.length === 0 && isSingleLine) {
                        if (!hasRealText) {
                            output = [];
                        } else {
                            stopCollecting = true;
                        }
                    }
                },
                chars(txt) {
                    if (stopCollecting) {
                        return;
                    }
                    if (stack.length === 0 && /^\s+$/.test(txt)) {
                        return;
                    }
                    hasRealText = hasRealText || !/^(&nbsp;)+$/.test(txt);
                    output.push(txt);
                }
            });

            return output.join('');
        }

        return {
            getDataWithDefaultStyleForRichText,
            getDataWithDefaultStyle,
            getDataWithDefaultStyleForString
        };
    }
);
