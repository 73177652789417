define([
    'lodash',
    'santa-components',
    'wixappsCore/core/proxyFactory',
    'wixappsCore/core/ViewContextMap',
    'wixappsCore/core/wixappsCoreSantaTypesDefinitions',
    'wixappsCore/core/proxySantaTypesDefinitions',
    'reactDOM',
    'zepto'
], function (_,
             santaComponents,
             /** wixappsCore.proxyFactory */ proxyFactory,
             /** wixappsCore.ViewContextMap */ ViewContextMap,
             wixappsCoreSantaTypes,
             proxySantaTypesDefinitions,
             ReactDOM,
             $) {
    'use strict';

    const {santaTypesDefinitions} = santaComponents;
    function isDataChanged(nextProps) {
        return !_.isEqual(nextProps.partData, this.props.partData) || // eslint-disable-line no-mixed-operators
            this.isChanged && this.isChanged(nextProps); // eslint-disable-line no-mixed-operators
    }

    function getViewProps() {
        const partApi = this.getPartApi();

        const viewProps = {
            getPartData: partApi.getPartData,
            getPartDefinition: partApi.getAppPartDefinition,
            resolveImageData: this.resolveImageData,
            getNormalizedDataPath: this.getNormalizedDataPath,
            getDataByPath: this.getDataByPath,
            setDataByPath: this.setDataByPath,
            getDataByFullPath: this.getDataByFullPath,
            getViewDef: this.getViewDef,
            getLocalizationBundle: partApi.getLocalizationBundle,
            classSet: this.classSet,
            setVar: this.setVar,
            compProp: this.props.compProp,
            compId: this.props.id,
            packageName: this.props.packageName,
            rootId: this.props.rootId,
            rootNavigationInfo: this.props.rootNavigationInfo,
            isExperimentOpen: this.props.isExperimentOpen,

            isViewerMode: this.props.isViewerMode,
            metaSiteId: this.props.metaSiteId,
            siteId: this.props.siteId,
            descriptor: this.props.descriptor,
            getAppPartZoomUrl: this.props.getAppPartZoomUrl,
            urlFormat: this.props.urlFormat,
            getAppPageUrl: this.props.getAppPageUrl,
            isDebugMode: this.props.isDebugMode,
            isQAMode: this.props.isQAMode,
            allTheme: this.props.allTheme,
            reportEvent: partApi.reportEvent,
            serviceTopology: this.props.serviceTopology,
            globalImageQuality: this.props.globalImageQuality,
            getMeasures: this.props.getMeasures,
            linkRenderInfo: this.props.linkRenderInfo,
            fontsMap: this.props.fontsMap,
            registerReLayoutPending: this.props.registerReLayoutPending,
            getComponentProps: this.props.getComponentProps,

            registerReLayout: this.registerReLayout
        };

        if (this.getViewProps) {
            return _.assign(viewProps, this.getViewProps());
        }
        return viewProps;
    }

    function scrollToView() {
        const DURATION = 1;
        const DELAY = 0;
        const domNode = ReactDOM.findDOMNode(this);
        const y = $(domNode).offset().top;
        this.props.animations.animate('BaseScroll', window, DURATION, DELAY, {y, ease: 'Sine.easeInOut'});
    }

    /**
     * @class wixappsCore.viewsRenderer
     *
     * @abstract {function(string, string, string): object} getViewDef
     * @abstract {function(string): object} getViewDataById
     * @abstract {function(): object} getLocalizationBundle Function that return the map from keys to translated text of the current language.
     * @abstract {function(): string} getPackageName get the application package name.
     */
    return {
        propTypes: {
            animations: santaTypesDefinitions.animations.isRequired,
            compProp: santaTypesDefinitions.Component.compProp.isRequired,
            FunctionLibrary: wixappsCoreSantaTypes.FunctionLibrary.isRequired,
            packageName: wixappsCoreSantaTypes.packageName.isRequired,
            getDataByPath: wixappsCoreSantaTypes.Data.getDataByPath.isRequired,
            setDataByPath: wixappsCoreSantaTypes.Data.setDataByPath.isRequired,
            formatName: wixappsCoreSantaTypes.formatName.isRequired,
            descriptor: wixappsCoreSantaTypes.descriptor,
            compExtraData: wixappsCoreSantaTypes.compExtraData,
            rootNavigationInfo: santaTypesDefinitions.Component.rootNavigationInfo.isRequired,
            rootId: santaTypesDefinitions.Component.rootId,

            // Proxy Props
            isViewerMode: santaTypesDefinitions.isViewerMode.isRequired,
            getMeasures: wixappsCoreSantaTypes.__DangerousSantaTypes.getMeasures.isRequired,
            getComponentProps: wixappsCoreSantaTypes.getComponentProps.isRequired,
            getAppPartZoomUrl: wixappsCoreSantaTypes.getAppPartZoomUrl.isRequired,
            getAppPageUrl: wixappsCoreSantaTypes.getAppPageUrl.isRequired,
            serviceTopology: santaTypesDefinitions.ServiceTopology.serviceTopology.isRequired,
            metaSiteId: santaTypesDefinitions.RendererModel.metaSiteId.isRequired,
            siteId: santaTypesDefinitions.RendererModel.siteId.isRequired,
            urlFormat: santaTypesDefinitions.urlFormat.isRequired,
            isDebugMode: santaTypesDefinitions.isDebugMode.isRequired,
            isQAMode: santaTypesDefinitions.isQAMode.isRequired,
            allTheme: santaTypesDefinitions.Theme.all.isRequired,
            globalImageQuality: santaTypesDefinitions.Media.globalImageQuality.isRequired,
            fontsMap: santaTypesDefinitions.Fonts.fontsMap.isRequired,
            linkRenderInfo: proxySantaTypesDefinitions.linkRenderInfo.isRequired,
            registerReLayoutPending: santaTypesDefinitions.Layout.registerReLayoutPending.isRequired,
            isExperimentOpen: santaTypesDefinitions.isExperimentOpen.isRequired
        },

        componentWillMount() {
            this.isViewContextMapDirty = false;
            this.viewContextMap = new ViewContextMap(this.handleViewContextMapUpdate);
            this.functionLibrary = new this.props.FunctionLibrary();
        },
        componentDidMount() {
            this.isViewContextMapDirty = false;
        },
        handleViewContextMapUpdate() {
            this.isViewContextMapDirty = true;
        },

        getNormalizedDataPath(contextPath, path) {
            if (contextPath === null) {
                return path;
            }
            return this.viewContextMap.resolvePath(contextPath, path);
        },

        getDataByPath(contextPath, path) {
            if (contextPath === null) {
                return this.getDataByFullPath(path);
            }

            const normalizedPath = this.getNormalizedDataPath(contextPath, path);

            return this.props.getDataByPath(this.props.packageName, normalizedPath);
        },

        getDataByFullPath(path) {
            function splitAndFlatten(arr) {
                return _.flattenDeep(_.map(arr, function (part) {
                    if (_.isString(part)) {
                        return part.split('.');
                    }
                    return part;
                }));
            }

            let normalizedPath = [];
            if (_.every(path, _.isArray)) {
                normalizedPath = _.map(path, function (part) {
                    const ret = splitAndFlatten(part);
                    return _.without(ret, 'this');
                });
            } else {
                normalizedPath = splitAndFlatten(path);
                normalizedPath = _.without(normalizedPath, 'this');
            }

            return this.props.getDataByPath(this.props.packageName, normalizedPath);
        },

        setDataByPath(contextPath, path, value) {
            const normalizedPath = this.getNormalizedDataPath(contextPath, path);

            return this.props.setDataByPath(this.props.packageName, normalizedPath, value);
        },

        setVar(contextPath, path, value, silent) {
            this.viewContextMap.setVar(contextPath, path, value);
            if (!silent) {
                // sababaaaaaaaaaaaaaaaa
                this.registerReLayout();
                this.forceUpdate();
            }
        },

        componentWillUpdate(nextProps, nextState) {
            if (nextState.$displayMode === 'content' && isDataChanged.call(this, nextProps)) {
                this.viewContextMap.resetContext();
            }
        },
        componentDidUpdate() {
            this.isViewContextMapDirty = false;
        },

        renderView(allowHeightResize) {
            const props = {
                viewProps: getViewProps.call(this),
                functionLibrary: this.functionLibrary,
                viewContextMap: this.viewContextMap,
                //structure: this.props.structure,

                viewName: this.getViewName(),
                formatName: this.props.formatName,
                parentId: this.props.id,
                id: 'root',
                ref: 'rootProxy',
                parentContextPath: null
            };

            let defaultVars = {
                viewName: props.viewName,
                partDirection: this.props.compProp.direction || 'ltr',
                compWidth: _.get(this, 'props.style.width'),
                compHeight: _.get(this, 'props.style.height')
            };

            // this.props.style might be undefined
            _.omit(defaultVars, _.isUndefined);

            if (this.logic) {
                props.logic = this.logic;
                if (this.logic.getViewVars) {
                    defaultVars = _.defaults(this.logic.getViewVars(this.props.compExtraData), defaultVars);
                    if (this.logic.shouldResetContext) {
                        const currentVars = this.viewContextMap.getVars('0');
                        if (this.logic.shouldResetContext(currentVars)) {
                            this.viewContextMap.resetContext();
                        }
                    }
                }
            }

            props.contextProps = {
                path: this.props.partDataLocation,
                vars: {
                    view: defaultVars,
                    proxy: {}
                },
                events: {
                    scrollToView: scrollToView.bind(this)
                },
                functionLibrary: {}
            };
            props.proxyLayout = {
                direction: defaultVars.partDirection
            };

            if (allowHeightResize) {
                props.proxyLayout.height = '100%';
            }

            const viewClass = proxyFactory.getProxyClass('View');
            return viewClass(props);
        }
    };
});
