define([
    'prop-types',
    'wixappsCore/core/typesConverter',
    'wixappsCore/proxies/mixins/baseImageProxy'
], function (PropTypes, /** wixappsCore.typesConverter */typesConverter, baseImageProxy) {
    'use strict';


    /**
     * @class proxies.Image
     * @extends proxies.mixins.baseImageProxy
     */
    return {
        mixins: [baseImageProxy],

        propTypes: {
            viewProps: PropTypes.shape({
                resolveImageData: PropTypes.func.isRequired,
                serviceTopology: PropTypes.object.isRequired,
                packageName: PropTypes.string.isRequired,
                globalImageQuality: PropTypes.object
            })
        },

        getCompData() {
            return typesConverter.image(
                this.proxyData,
                this.props.viewProps.resolveImageData,
                this.props.viewProps.serviceTopology,
                this.props.viewProps.packageName,
                this.props.viewProps.globalImageQuality
            );
        }
    };
});
