define([
    'lodash',
    'santa-components',
    'coreUtils',
    'componentsCore',
    'wixappsCore/core/wixappsCoreSantaTypesDefinitions',
    'wixappsCore/core/wixappsSantaTypesUtil',
    'wixappsCore/core/expressions/functionLibrary',
    'wixappsCore/core/wixappsLogger',
    'wixappsCore/util/localizer',
    'wixappsCore/core/logicFactory',
    'wixappsCore/util/wixappsUrlParser'
], function (_, santaComponents, coreUtils, componentsCore, wixappsCoreSantaTypesDefinitions, wixappsSantaTypesUtil, FunctionLibrary, wixappsLogger, localizer, logicFactory, wixappsUrlParser) {
    'use strict';

    const {createGlobalSantaTypesFetcher, createComponentSantaTypeFetcher} = coreUtils.propsSelectorsUtils;
    const {createWixappsComponentFetcher} = wixappsSantaTypesUtil;
    const {santaTypesDefinitions} = santaComponents;
    const getCompData = wixappsSantaTypesUtil.getCompData;
    const siteDataUtils = coreUtils.siteDataUtils;

    function getDataAspect(state) {
        return state.siteAPI.getSiteAspect('wixappsDataAspect');
    }

    if (typeof WeakMap !== 'undefined') {
        _.memoize.Cache = WeakMap; //eslint-disable-line no-undef
    }

    function memoizeSiteData(func) {
        return _.memoize(func, function (state) {
            return state.siteData;
        });
    }

    const packageName = createComponentSantaTypeFetcher(function (state, props) {
        return wixappsSantaTypesUtil.getPackageName(state, props);
    });

    const descriptor = createWixappsComponentFetcher(function (state, props) {
        const dataAspect = getDataAspect(state);
        const pkgName = state.fetchSantaType(wixappsCoreSantaTypesDefinitions.packageName, state, props);
        return dataAspect.getDescriptor(pkgName);
    }, true);

    const reportEvent = createGlobalSantaTypesFetcher(function (state) {
        return function (eventData, params) {
            return wixappsLogger.reportEvent(state.siteData, eventData, params);
        };
    }, 'reportEvent');

    const reportError = createGlobalSantaTypesFetcher(function (state) {
        return function (errorData, params) {
            return wixappsLogger.reportError(state.siteData, errorData, params);
        };
    }, 'reportError');

    const pageTitle = createGlobalSantaTypesFetcher(function (state) {
        return state.siteAPI.setPageTitle;
    }, 'pageTitle');

    const appService = createComponentSantaTypeFetcher(function (state, props) {
        const compData = getCompData(state, props);
        return state.siteData.getClientSpecMapEntry(compData.appInnerID);
    });

    const compExtraData = createWixappsComponentFetcher(function (state, props) {
        const compData = getCompData(state, props);
        const dataAspect = getDataAspect(state);
        const pkgName = state.fetchSantaType(wixappsCoreSantaTypesDefinitions.packageName, state, props);
        return dataAspect.getExtraDataByCompId(pkgName, compData.id);
    });

    const getDataByPath = createGlobalSantaTypesFetcher(function (state) {
        return getDataAspect(state).getDataByPath;
    }, 'getDataByPath');

    const setDataByPath = createGlobalSantaTypesFetcher(function (state) {
        return getDataAspect(state).setDataByPath;
    }, 'setDataByPath');

    const setBatchedData = createGlobalSantaTypesFetcher(function (state) {
        return getDataAspect(state).setBatchedData;
    }, 'setBatchedData');

    const formatName = createGlobalSantaTypesFetcher(function (state, props) {
        return props.formatName || state.fetchSantaType(santaTypesDefinitions.isMobileView, state, props) ? 'Mobile' : '';
    }, 'formatName');

    const functionLibrary = createComponentSantaTypeFetcher(memoizeSiteData(function (state, props) {
        function getColor(colorId) {
            const colorsMap = state.fetchSantaType(santaTypesDefinitions.Theme.colorsMap, state, props);
            return coreUtils.colorParser.getColor(colorsMap, colorId);
        }

        const siteData = state.siteData;

        const isMobile = state.fetchSantaType(santaTypesDefinitions.isMobileView, state, props);
        const zoom = siteData.mobile.getZoom();
        state.fetchSantaType(santaTypesDefinitions.Theme.colorsMap, state, props); // Read the colors data in order to trigger render when the theme colors changes.
        const experimentContext = {
            rendererModel: {
                runningExperiments: siteDataUtils.getRendererModel(siteData, 'runningExperiments')
            }
        };
        const siteAPI = state.siteAPI;
        const serviceTopologyPointer = siteAPI.getPointers().general.getServiceTopology();
        const serviceTopology = siteAPI.getDisplayedDAL().get(serviceTopologyPointer);
        return FunctionLibrary.bind(null, isMobile, zoom, serviceTopology, getColor, experimentContext);
    }));

    const localizationBundle = createWixappsComponentFetcher(function (state, props) {
        const dataAspect = getDataAspect(state);
        const pkgName = state.fetchSantaType(wixappsCoreSantaTypesDefinitions.packageName, state, props);
        return localizer.getLocalizationBundleForPackage(dataAspect, pkgName, state.siteData);
    });

    const packageMetadata = createWixappsComponentFetcher(function (state, props) {
        const dataAspect = getDataAspect(state);
        const pkgName = state.fetchSantaType(wixappsCoreSantaTypesDefinitions.packageName, state, props);
        return dataAspect.getMetadata(pkgName) || {};
    });

    const Logic = createComponentSantaTypeFetcher(function (state, props) {
        const compData = getCompData(state, props);
        return logicFactory.getLogicClass(compData.appPartName);
    });

    const getAppPageUrl = createGlobalSantaTypesFetcher(memoizeSiteData(function (state) {
        return wixappsUrlParser.getAppPageUrl.bind(wixappsUrlParser, state.siteData);
    }), 'getAppPageUrl');

    const appPageParams = createGlobalSantaTypesFetcher(function (state) {
        return wixappsUrlParser.getAppPageParams(state.siteData);
    }, 'appPageParams');

    const getAppPartZoomUrl = createGlobalSantaTypesFetcher(memoizeSiteData(function (state) {
        return wixappsUrlParser.getAppPartZoomUrl.bind(wixappsUrlParser, state.siteData);
    }), 'getAppPartZoomUrl');

    const getMeasures = createGlobalSantaTypesFetcher(memoizeSiteData(function (state) {
        return function getCompMeasures(id) {
            return _.transform(state.siteData.measureMap, function (acc, value, key) {
                if (value[id]) {
                    acc[key] = value[id];
                }
            }, {});
        };
    }), 'getMeasures');

    const getComponentProps = createGlobalSantaTypesFetcher(memoizeSiteData(function (state) {
        return function (compStructure, rootId, parentProps) {
            const compClass = componentsCore.compRegistrar.getCompReactClass(compStructure.componentType);
            return state.siteAPI.getCompProps(compClass, compStructure, state.siteAPI, rootId, null, parentProps);
        };
    }), 'getComponentProps');

    const forceUpdate = createGlobalSantaTypesFetcher(function (state) {
        return state.siteAPI.forceUpdate;
    }, 'forceUpdate');

    return {
        __DangerousSantaTypes: {
            getMeasures,
            forceUpdate
        },
        getComponentProps,
        localizationBundle,
        Data: {
            getDataByPath,
            setDataByPath,
            setBatchedData
        },
        getAppPageUrl,
        appPageParams,
        getAppPartZoomUrl,
        FunctionLibrary: functionLibrary,
        formatName,
        compExtraData,
        appService,
        descriptor,
        packageName,
        reportEvent,
        reportError,
        setPageTitle: pageTitle,
        packageMetadata,
        Logic
    };
});
