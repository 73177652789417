define(['lodash', 'prop-types', 'santa-components', 'wixappsCore/core/proxySantaTypesDefinitions', 'coreUtils', 'wixappsCore/proxies/mixins/zoomProxy'], function (_, PropTypes, santaComponents, proxySantaTypesDefinitions, coreUtils, zoomProxy) {
    'use strict';

    const {santaTypesDefinitions} = santaComponents;
    const socialCounterDatabaseAPI = coreUtils.socialCounterDatabaseAPI;
    const wixappsClassicsLogger = coreUtils.wixappsClassicsLogger;

    function urlToObject(url) {
        if (!url) {
            return {};
        }
        const keys = _.filter(url.split('/'), (v, i) => i % 2 === 0);
        const values = _.filter(url.split('/'), (v, i) => i % 2 === 1);
        return _.zipObject(keys, values);
    }

    function objectToUrl(obj) {
        return _.reduce(obj, (cur, val, key) => `${cur}/${key}/${val}`, '').substring(1);
    }

    function getPageTitle(pageData) {
        return pageData.pageUriSEO || pageData.title;
    }

    function generateUrlFromTitleAndDate(getAppPageUrl, pageId, proxyData, title) {
        const titleTemplate = _.template('<%= year %>/<%= month %>/<%= day %>/<%= title %>');
        const date = new Date(proxyData.date.iso);

        return getAppPageUrl(pageId, titleTemplate({
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
            title
        }), '');
    }

    function isBlogSinglePostPage(pageDataItem) {
        const BLOG_SINGLE_POST_APP_ID = '7326bfbb-4b10-4a8e-84c1-73f776051e10';

        return pageDataItem && pageDataItem.type === 'AppPage' &&
            pageDataItem.appPageType === 'AppPage' &&
            pageDataItem.appPageId === BLOG_SINGLE_POST_APP_ID;
    }

    function getBlogSinglePostUrl(getAppPageUrl, urlFormat, pageData, proxyData, title) {
        let href;

        if (urlFormat === coreUtils.siteConstants.URL_FORMATS.SLASH) {
            if (proxyData.permalink) {
                href = getAppPageUrl(pageData.id, proxyData.permalink);
            } else {
                href = generateUrlFromTitleAndDate(getAppPageUrl, pageData.id, proxyData, encodeURIComponent(title));
            }
        } else {
            href = getAppPageUrl(pageData.id, proxyData._iid, title);
        }

        return href;
    }

    function reportBlogCounter(blogStoreId, counterType, counterName) {
        socialCounterDatabaseAPI.updateCounter(counterType, counterName, 1, blogStoreId);
    }

    function tagClickedHandler(props, tagName) {
        props.viewProps.reportEvent(wixappsClassicsLogger.events.TAG_CLICKED, {
            site_id: props.viewProps.siteId
        });
        const reportTag = _.unescape(tagName);
        reportBlogCounter(props.viewProps.blogStoreId, 'tag', reportTag);
    }

    function categoryClickedHandler(props, categoryName) {
        props.viewProps.reportEvent(wixappsClassicsLogger.events.CATEGORY_CLICKED, {
            site_id: props.viewProps.siteId
        });
        const reportCategory = _.unescape(categoryName);
        reportBlogCounter(props.viewProps.blogStoreId, 'category', reportCategory);
    }

    function appPageLinkClickedHandler(reportEvent, metaSiteId, siteId, postId) {
        reportEvent(wixappsClassicsLogger.events.SINGLE_POST_LINK_CLICKED, {
            post_id: postId,
            site_id: siteId,
            msid: metaSiteId
        });
    }

    const resolvers = {
        appPageLink(props, pageData, proxyData) {
            const title = _.unescape(proxyData.title).replace(/(?![a-z0-9])(?!\s)[\x00-\x7F]/gi, '').replace(/\s+/g, '-'); //eslint-disable-line no-control-regex
            const isBlogSinglePostLink = isBlogSinglePostPage(pageData);
            return {
                href: isBlogSinglePostLink ?
                    getBlogSinglePostUrl(props.viewProps.getAppPageUrl, props.viewProps.urlFormat, pageData, proxyData, title) :
                    props.viewProps.getAppPageUrl(pageData.id, proxyData._iid, title),
                onClick: props.viewProps.isViewerMode && isBlogSinglePostLink ? appPageLinkClickedHandler.bind(this, props.viewProps.reportEvent, props.viewProps.metaSiteId, props.viewProps.siteId, proxyData._iid) : undefined
            };
        },
        date(props, pageData, proxyData) {
            const filter = `date/${proxyData.value}`;
            return {
                href: props.viewProps.getAppPageUrl(pageData.id, filter, getPageTitle(pageData))
            };
        },
        tag(props, pageData, proxyData) {
            const tagName = proxyData.key || proxyData;
            const filter = `tag/${encodeURIComponent(tagName)}`;
            return {
                href: props.viewProps.getAppPageUrl(pageData.id, filter, getPageTitle(pageData)),
                onClick: props.viewProps.isViewerMode ? tagClickedHandler.bind(this, props, tagName) : undefined
            };
        },
        category(props, pageData, proxyData) {
            const category = proxyData;
            const filter = `category/${encodeURIComponent(category.name)}`;
            return {
                href: props.viewProps.getAppPageUrl(pageData.id, filter, getPageTitle(pageData)),
                onClick: props.viewProps.isViewerMode ? categoryClickedHandler.bind(this, props, category.name) : undefined
            };
        },
        author(props, pageData, proxyData) {
            const filter = `author/${encodeURIComponent(proxyData.author.replace(/\s/g, '-'))}`;
            return {
                href: props.viewProps.getAppPageUrl(pageData.id, filter, getPageTitle(pageData))
            };
        },
        //all of these will work as long as we don't have blog page in popup
        prevPage(props, pageData) {
            const filter = props.viewProps.rootNavigationInfo.pageAdditionalData || '';
            const filterParts = urlToObject(filter);
            uniformPageKeyInFilterParts(filterParts);
            if (filterParts.page && Number(filterParts.page)) {
                filterParts.page = Number(filterParts.page) - 1;
            }
            if (Number(filterParts.page) === 0) {
                delete filterParts.page;
            }
            return {
                href: props.viewProps.getAppPageUrl(pageData.id, objectToUrl(filterParts), getPageTitle(pageData)),
                pathToItems: 'prevPage'
            };
        },
        nextPage(props, pageData) {
            const filter = props.viewProps.rootNavigationInfo.pageAdditionalData || '';
            const filterParts = urlToObject(filter);
            uniformPageKeyInFilterParts(filterParts);
            if (filterParts.page && Number(filterParts.page)) {
                filterParts.page = Number(filterParts.page) + 1;
            } else {
                filterParts.page = 1;
            }
            return {
                href: props.viewProps.getAppPageUrl(pageData.id, objectToUrl(filterParts), getPageTitle(pageData)),
                pathToItems: 'nextPage'
            };
        },
        numberedPage(props, pageData, proxyData) {
            const filter = props.viewProps.rootNavigationInfo.pageAdditionalData || '';
            const filterParts = urlToObject(filter);
            uniformPageKeyInFilterParts(filterParts);
            filterParts.page = parseInt(proxyData, 10);
            if (Number(filterParts.page) === 0) {
                delete filterParts.page;
            }
            return {
                href: props.viewProps.getAppPageUrl(pageData.id, objectToUrl(filterParts), getPageTitle(pageData)),
                pathToItems: filterParts.page || 0
            };
        }
    };

    // Without the uniforming "#!blog/cy8xy/Page/1" will be changed to "#!blog/cy8xy/Page/1/page/2".
    function uniformPageKeyInFilterParts(filterParts) {
        if (_.has(filterParts, 'Page')) {
            filterParts.page = filterParts.Page;
            delete filterParts.Page;
        }
    }

    /**
     * get the data of the page which matches the appPageId on the proxy data
     * @param appPageId
     * @param pages
     * @returns {*}
     */
    function getPageData(appPageId, pages) {
        if (appPageId === 'samePage') {
            const pageId = this.props.viewProps.rootNavigationInfo.pageId;
            return _.find(pages, {id: pageId});
        }
        return _.find(pages, pageData => pageData.appPageId && pageData.appPageId === appPageId);
    }


    /**
     * @class proxies.AppLink
     * @extends proxies.mixins.zoomProxy
     */
    return {
        mixins: [zoomProxy],

        propTypes: {
            viewProps: PropTypes.shape({
                linkRenderInfo: proxySantaTypesDefinitions.linkRenderInfo.isRequired,
                reportEvent: PropTypes.func.isRequired,
                metaSiteId: santaTypesDefinitions.RendererModel.metaSiteId.isRequired,
                siteId: santaTypesDefinitions.RendererModel.siteId.isRequired,
                blogStoreId: PropTypes.object,
                rootNavigationInfo: santaTypesDefinitions.Component.rootNavigationInfo.isRequired,
                urlFormat: santaTypesDefinitions.urlFormat.isRequired,
                getAppPageUrl: PropTypes.func.isRequired,
                isViewerMode: PropTypes.bool.isRequired
            })
        },

        getCustomProps() {
            const linkType = this.getCompProp('linkType') || 'appPageLink';

            const pageId = this.getCompProp('pageId');
            const pageData = getPageData.call(this, pageId, this.props.viewProps.linkRenderInfo.pagesDataItemsMap);

            if (pageData && linkType && resolvers[linkType]) {
                return resolvers[linkType](this.props, pageData, this.proxyData);
            }

            return {};
        }
    };
});
