define([
    'lodash',
    'componentsCore',
    'wixappsCore/proxies/mixins/textProxy',
    'wixappsCore/core/typesConverter',
    'wixappsCore/core/proxySantaTypesDefinitions',
    'santa-components',
    'prop-types'
], function (
    _,
    componentsCore,
    textProxy,
    /** wixappsCore.typesConverter */typesConverter,
    proxySantaTypesDefinitions,
    santaComponents,
    PropTypes
) {
    'use strict';

    function transformSkinProperties(refData) {
        refData[''].parentConst = santaComponents.utils.createReactElement.bind(null, 'span');
        return _.set(refData, ['richTextContainer', 'style', 'height'], 'initial');
    }

    /**
     * @class proxies.InlineText
     * @extends proxies.mixins.textProxy
     */
    return {
        mixins: [textProxy],
        propsTypes: {
            viewProps: PropTypes.shape({
                linkRenderInfo: proxySantaTypesDefinitions.linkRenderInfo.isRequired
            }
            )
        },
        renderProxy() {
            const data = this.proxyData;

            const formattedText = this.createFormattedText(data, 'span');

            const componentType = 'wysiwyg.viewer.components.WRichText';

            const compData = typesConverter.richText(formattedText, data.links, this.props.viewProps.linkRenderInfo.pagesDataItemsMap);
            const props = this.getRichTextChildCompProps(componentType, transformSkinProperties, compData);

            return componentsCore.compRegistrar.getCompClass(componentType, true)(props);
        }
    };
});
