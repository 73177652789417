define(['componentsCore', 'wixappsCore/proxies/mixins/baseProxy'], function (componentsCore, baseProxy) {
    'use strict';

    /**
     * @class proxies.VerticalLine
     * @extends proxies.mixins.baseProxy
     */
    return {
        mixins: [baseProxy],
        renderProxy() {
            const componentType = 'wysiwyg.viewer.components.VerticalLine';
            const props = this.getChildCompProps(componentType);
            return componentsCore.compRegistrar.getCompClass(componentType, true)(props);
        }
    };
});
