define(['componentsCore', 'wixappsCore/proxies/mixins/baseProxy', 'lodash'], function (componentsCore, baseProxy, _) {
    'use strict';

    const componentType = 'wysiwyg.viewer.components.FiveGridLine';

    function transformSkinProperties(refData) {
        refData[''] = _.merge({}, refData[''], {style: {position: 'relative'}});

        return refData;
    }

    /**
     * @class proxies.HorizontalLine
     * @extends proxies.mixins.baseProxy
     */
    return {
        mixins: [baseProxy],
        statics: {
            componentType
        },
        renderProxy() {
            const props = this.getChildCompProps(componentType, transformSkinProperties);
            return componentsCore.compRegistrar.getCompClass(componentType, true)(props);
        }
    };
});
