define(['componentsCore'], function (componentsCore) {
    'use strict';

    /**
     * @class components.ImageButtonWithText
     * @extends {core.skinBasedComp}
     */
    const imageButtonWithText = {
        displayName: 'ImageButtonWithText',

        mixins: [componentsCore.mixins.skinBasedComp],

        getSkinProperties: function getSkinProperties() {
            return {
                button: {className: getClassSet.call(this)},
                buttonIcon: {src: this.props.compData.iconSource},
                buttonLabel: {children: this.props.compData.label},
                buttonExtraInfo: {children: this.props.compData.extraInfo}
            };
        }
    };

    function getClassSet() {
        const classesMap = {};
        classesMap[`direction-${this.props.compProp.direction}`] = true;
        classesMap['label-empty'] = !this.props.compData.label;
        classesMap['extraInfo-empty'] = !this.props.compData.extraInfo;
        classesMap[`size-${this.props.compProp.size}`] = true;
        classesMap[`type-${this.props.compData.type}`] = true;
        classesMap['border-radius-editable'] = true;
        return this.classSet(classesMap);
    }

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.ImageButtonWithText', imageButtonWithText);

    return imageButtonWithText;
});
