define([
    'lodash',
    'prop-types',
    'componentsCore',
    'wixappsCore/proxies/mixins/boxProxy',
    'wixappsCore/core/layoutBasedHelper'
], function (_, PropTypes, componentsCore, boxProxy, layoutHelper) {
    'use strict';

    // Inherit flex style
    const flexStyle = {display: 'inherit', 'flex-direction': 'inherit'};

    /**
     * @class proxies.Area
     * @extends proxies.mixins.baseProxy
     */
    return {
        mixins: [boxProxy],
        propTypes: {
            orientation: PropTypes.string
        },
        getChildrenOrientation() {
            return this.getCompProp('orientation') || 'vertical';
        },

        getComponentName() {
            return 'wixapps.integration.components.Area';
        },

        getReactClass() {
            return componentsCore.compRegistrar.getCompClass(this.getComponentName(), true);
        },

        transformSkinProperties(refData) {
            const proxyStyleDef = this.getStyleDef();
            if (layoutHelper.isLayoutBasedHeight(proxyStyleDef, this.props.orientation)) {
                refData.bg = refData.bg || {};
                refData.bg.style = _.merge({}, refData.bg.style, {height: '100%'});
            }
            refData.inlineContent.style = _.merge({}, refData.inlineContent.style, flexStyle, {position: 'relative', width: '100%'});

            return refData;
        }
    };
});
