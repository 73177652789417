define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';

    const THINGY_CHAR = '@';

    const localizer = {
        macroPattern: new RegExp(`^${THINGY_CHAR}[0-9a-zA-Z_\\-\\.]*${THINGY_CHAR}$`),
        thingyPattern: new RegExp(THINGY_CHAR, 'g')
    };

    Object.freeze(localizer);

    function getTranslatedValue(key, map) {
        if (_.has(map, key)) {
            return map[key];
        }
        return `[${key}] - not found`;
    }

    return {
        getLocalizationBundleForPackage(dataAspect, packageName, siteData) {
            const cookie = coreUtils.siteDataUtils.getRequestModel(siteData, 'cookie');
            const languageFromModel = coreUtils.siteDataUtils.getRequestModel(siteData, 'language');
            const lang = coreUtils.wixUserApi.getLanguage(cookie, siteData.currentUrl, languageFromModel).toLowerCase();
            const descriptor = dataAspect.getDescriptor(packageName);
            return descriptor && (descriptor.lang[lang] || descriptor.lang.en);
        },

        localize(str, localizationBundle) {
            let macro, key, res;

            while ((macro = localizer.macroPattern.exec(str)) !== null) {
                macro = macro[0];
                key = macro.replace(localizer.thingyPattern, '');
                res = getTranslatedValue(key, localizationBundle);
                str = str.replace(macro, res);
            }

            return str;
        }
    };
});
