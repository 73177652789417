define(['santa-components', 'wixappsCore/proxies/mixins/boxProxy'], function (santaComponents, boxProxy) {
    'use strict';

    /**
     * @class proxies.VBox
     * @extends proxies.mixins.boxProxy
     */
    return {
        mixins: [boxProxy],
        getChildrenOrientation() {
            return 'vertical';
        },

        getReactClass() {
            return santaComponents.utils.createReactElement.bind(null, 'div');
        }
    };
});
