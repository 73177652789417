define(['santa-components', 'lodash', 'componentsCore', 'prop-types', 'coreUtils', 'wixappsCore/proxies/mixins/templateBasedChildrenProxy', 'wixappsCore/proxies/mixins/baseCompositeProxy'], function (santaComponents, _, componentsCore, PropTypes, coreUtils, templateBasedChildrenProxy, baseCompositeProxy) {
    'use strict';

    const galleryPagingCalculations = componentsCore.utils.galleryPagingCalculations;

    /**
     * @class proxies.ColumnGalleryProxy
     * @extends proxies.mixins.baseComposite
     */
    return {
        mixins: [baseCompositeProxy, templateBasedChildrenProxy],
        propTypes: {
            viewDef: PropTypes.object.isRequired
        },
        componentWillUpdate(nextProps) {
            const nextMaxPage = Math.ceil(this.proxyData.length / this.getItemsNumberPerPage(nextProps));
            const maxPage = this.getVar('maxPage');
            if (nextMaxPage !== maxPage) {
                this.setVar('maxPage', nextMaxPage, true);
            }
        },

        componentWillMount() {
            const maxPage = Math.ceil(this.proxyData.length / this.getItemsNumberPerPage(this.props));
            this.setVar('maxPage', maxPage, true);
        },

        getItemsNumberPerPage(props) {
            let itemsPerPage = parseInt(this.getCompProp('itemsPerPage', props.viewDef), 10);
            if (_.isNaN(itemsPerPage) || !_.isNaN(itemsPerPage) && itemsPerPage >= this.proxyData.length) { // eslint-disable-line no-mixed-operators
                itemsPerPage = this.proxyData.length;
            }
            return itemsPerPage;
        },

        createChildProxy(indexInData, itemsPerPage, cols) {
            const dataPath = this.getViewDefProp('data') || 'this';
            const childViewDef = this.getChildTemplateDefinition(indexInData, itemsPerPage);

            const props = this.getChildProxyProps(childViewDef, [dataPath, indexInData], {});

            return santaComponents.utils.createReactElement('div', {
                key: indexInData,
                style: _.assign({
                    display: 'inline-block',
                    verticalAlign: 'top',
                    width: `${100 / cols}%`
                }, coreUtils.style.prefix({
                    boxSizing: 'border-box'
                }))
            }, this.renderChildProxy(childViewDef, indexInData, null, props));
        },

        getCurrentPositionInData(itemsPerPage, currentPage) {
            if (itemsPerPage === this.proxyData.length) {
                return 0;
            }
            return currentPage * itemsPerPage;
        },

        getCurrentPage() {
            let currentPage = this.getVar('currentPage') || 1;
            currentPage = parseInt(currentPage, 10) - 1; //convert to zero based pages
            return currentPage;
        },

        renderProxy() {
            const itemsPerPage = this.getItemsNumberPerPage(this.props);
            const currentPage = this.getCurrentPage();
            const startIndex = this.getCurrentPositionInData(itemsPerPage, currentPage);
            const cols = this.getCompProp('columns') || 3;

            const children = [];
            const lastIndex = galleryPagingCalculations.getLastItemIndex(this.proxyData, startIndex, itemsPerPage);
            for (let i = startIndex; i <= lastIndex; i++) {
                children.push(this.createChildProxy(i, itemsPerPage, cols));
            }

            const props = this.getChildCompProps();
            props['data-proxy-name'] = 'PaginatedColumnGalleryProxy';
            props['data-direction'] = this.getVar('partDirection') || 'ltr';
            props['data-horizontal-gap'] = this.getCompProp('horizontalGap') || 0;
            props['data-vertical-gap'] = this.getCompProp('verticalGap') || 0;
            props['data-columns'] = cols;

            return santaComponents.utils.createReactElement('div', props, children);
        }
    };
});
