define(['santa-components', 'wixappsCore/core/proxySantaTypesDefinitions', 'prop-types', 'coreUtils', 'wixappsCore/core/typesConverter', 'wixappsCore/proxies/mixins/boxProxy'
], function (santaComponents, proxySantaTypesDefinitions, PropTypes, coreUtils, /** wixappsCore.typesConverter */typesConverter, boxProxy) {
    'use strict';

    const {santaTypesDefinitions} = santaComponents;

    /**
     * @class proxies.Link
     * @extends proxies.mixins.boxProxy
     */
    return {
        mixins: [boxProxy],

        propsTypes: {
            viewProps: PropTypes.shape({
                linkRenderInfo: proxySantaTypesDefinitions.linkRenderInfo.isRequired,
                rootNavigationInfo: santaTypesDefinitions.Component.rootNavigationInfo.isRequired
            }
            )
        },

        getChildrenOrientation() {
            return this.getCompProp('orientation') || 'vertical';
        },

        getReactClass() {
            return santaComponents.utils.createReactElement.bind(null, 'a');
        },

        getCustomProps() {
            const data = typesConverter.link(this.proxyData, this.props.viewProps.linkRenderInfo.pagesDataItemsMap);
            const props = coreUtils.linkRenderer.renderLink(data, this.props.viewProps.linkRenderInfo, this.props.viewProps.rootNavigationInfo);
            props.className = 'wixAppsLink';

            return props;
        }
    };
});
