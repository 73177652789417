define([
    'prop-types',
    'wixappsCore/core/typesConverter',
    'wixappsCore/proxies/mixins/baseImageProxy'
], function (PropTypes, /** wixappsCore.typesConverter */typesConverter, baseImageProxy) {
    'use strict';

    /**
     * @class proxies.VideoThumb
     * @extends proxies.mixins.baseImageProxy
     */
    return {
        mixins: [baseImageProxy],
        propTypes: {
            viewProps: PropTypes.shape({
                classSet: PropTypes.func.isRequired,
                resolveImageData: PropTypes.func.isRequired,
                serviceTopology: PropTypes.object.isRequired
            })
        },

        getProxyCustomCssClass() {
            return this.props.viewProps.classSet({'videoIndicator': true});
        },

        getCompData() {
            return typesConverter.videoThumb(this.proxyData, this.props.viewProps.resolveImageData, this.props.viewProps.serviceTopology);
        }
    };
});
