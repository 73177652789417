define(['wixappsCore/proxies/mixins/siteButtonProxy', 'lodash'], function (siteButtonProxy, _) {
    'use strict';

    /**
     * @class proxies.Button
     * @extends proxies.mixins.siteButton
     */
    return {
        mixins: [siteButtonProxy],
        transformSkinProperties(refData) {
            const rootProps = {
                'data-proxy-name': 'Button',
                onClick: this.handleViewEvent
            };
            refData[''] = _.merge({}, refData[''], rootProps); // eslint-disable-line santa/no-side-effects

            return refData;
        },

        getAdditionalButtonStyle() {
            const additionalStyle = {};
            const label = this.getCompProp('label') || this.proxyData;
            if (label === '') {
                additionalStyle.display = 'none';
            }
            return additionalStyle;
        },

        getDefaultLabel() {
            return 'Submit';
        }
    };
});
