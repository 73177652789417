define(['componentsCore', 'wixappsCore/proxies/mixins/baseCompositeProxy', 'wixappsCore/components/toggle/toggle'], function (componentsCore, baseCompositeProxy) {
    'use strict';
    function createInnerProxies() {
        const templateDef = this.getCompProp('templates');
        const onProxyInstance = this.renderChildProxy(templateDef.on, 'on');
        const offProxyInstance = this.renderChildProxy(templateDef.off, 'off');

        return [onProxyInstance, offProxyInstance];
    }

    /**
     * @class proxies.Toggle
     * @extends proxies.mixins.baseComposite
     */
    return {
        mixins: [baseCompositeProxy],

        renderProxy() {
            const children = createInnerProxies.call(this);
            const componentType = 'wixapps.integration.components.Toggle';
            const props = this.getChildCompProps(componentType);
            props.initialState = this.getCompProp('initialState') || 'off';

            return componentsCore.compRegistrar.getCompClass(componentType, true)(props, children);
        }
    };
});
