define([], function () {
    'use strict';

    let shouldApplyAutomationAttributes = false; //eslint-disable-line santa/no-module-state

    /**
     * @class wixappsCore.wixappsConfiguration
     */
    return {
        shouldApplyAutomationAttributes(props) {
            return shouldApplyAutomationAttributes && props.isQAMode;
        },

        applyAutomationAttributes() {
            shouldApplyAutomationAttributes = true;
        }
    };
});
