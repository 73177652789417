define([
    'wixappsCore/core/viewsRenderer',
    'wixappsCore/core/viewsCustomizer',
    'wixappsCore/core/proxyFactory',
    'wixappsCore/core/logicFactory',
    'wixappsCore/core/styleCollector',
    'wixappsCore/core/appPartCustomCss',
    'wixappsCore/core/styleData',
    'wixappsCore/core/ViewContextMap',
    'wixappsCore/core/wixappsDataHandler',
    'wixappsCore/core/wixappsLogger',
    'wixappsCore/core/wixappsSantaTypesUtil',
    'wixappsCore/core/wixappsCoreSantaTypesDefinitions',

    'wixappsCore/proxies/mixins/baseProxy',
    'wixappsCore/proxies/mixins/inputProxy',
    'wixappsCore/core/typesConverter',
    'wixappsCore/util/videoThumbDataHandler',
    'wixappsCore/proxies/mixins/baseCompositeProxy',
    'wixappsCore/util/spacersCalculator',
    'wixappsCore/core/wixappsConfiguration',
    'wixappsCore/core/linksConverter',
    'wixappsCore/util/localizer',
    'wixappsCore/core/expressions/functionLibrary',
    'wixappsCore/util/viewsUtils',
    'wixappsCore/util/memoizedViewsUtils',
    'wixappsCore/util/richTextUtils',
    'wixappsCore/util/wixappsUrlParser',
    'wixappsCore/util/typeNameResolver',
    'wixappsCore/util/styleMapping',
    'wixappsCore/core/expressions/expression',
    'wixappsCore/core/wixappsCoreSantaTypes',
    'wixappsCore/core/wixappsProxySantaTypes',
    'componentsCore',
    'skins',
    'wixappsCore/skins/skins.json',

    // require for side effects
    'wixappsCore/proxies/proxyMap',
    'wixappsCore/core/wixappsDataAspect',
    'wixappsLayout',

    // components
    'wixappsCore/components/icon/icon',
    'wixappsCore/components/toggle/toggle',
    'wixappsCore/components/wixAppsImageButton/wixAppsImageButton',
    'wixappsCore/components/imageButtonWithText/imageButtonWithText',

    'textArea',
    'imageZoom',
    'matrixGallery',
    'slideShowGallery',
    'comboBoxInput',
    'dialogs'
], function (viewsRenderer, viewsCustomizer, proxyFactory, logicFactory, styleCollector, appPartCustomCss, styleData, ViewContextMap, wixappsDataHandler, wixappsLogger, wixappsSantaTypesUtil, wixappsCoreSantaTypesDefinitions, baseProxy, inputProxy,
             typesConverter, videoThumbDataHandler, baseCompositeProxy, spacersCalculator, wixappsConfiguration, linksConverter, localizer, FunctionLibrary, viewsUtils, memoizedViewsUtils, richTextUtils, wixappsUrlParser,
             typeNameResolver, styleMapping, expression, wixappsCoreSantaTypes, wixappsProxySantaTypes, componentsCore, skinsPackage, skinsJson) {
    'use strict';

    skinsPackage.skinsMap.addBatch(skinsJson);
    componentsCore.santaTypesFetchersRegistry.registerFetchersNamespace('wixappsCoreSantaTypes', wixappsCoreSantaTypes);
    componentsCore.santaTypesFetchersRegistry.registerFetchersNamespace('wixappsProxySantaTypes', wixappsProxySantaTypes);

    /**
     * @class wixappsCore
     */
    return {
        viewsRenderer,
        viewsCustomizer,
        proxyFactory,
        baseProxy,
        inputProxy,
        baseCompositeProxy,
        typesConverter,
        logicFactory,
        ViewContextMap,
        CoreSantaTypes: wixappsCoreSantaTypesDefinitions,
        wixappsDataHandler,
        wixappsLogger,
        wixappsSantaTypesUtil,
        videoThumbDataHandler,
        styleCollector,
        appPartCustomCss,
        spacersCalculator,
        wixappsConfiguration,
        linksConverter,
        localizer,
        FunctionLibrary,
        viewsUtils,
        memoizedViewsUtils,
        wixappsUrlParser,
        typeNameResolver,
        styleData,
        richTextUtils,
        convertStringToPrimitive: expression.convertStringToPrimitive,
        styleMapping,
        expression: {
            evaluate: expression.evaluate,
            isExpression: expression.isExpression
        }
    };
});
