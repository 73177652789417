define(['lodash', 'santa-components', 'componentsCore'], function (_, santaComponents, componentsCore) {
    'use strict';

    /**
     * @class components.Icon
     * @extends {core.skinBasedComp}
     * @property {comp.properties} props
     */
    const icon = {
        displayName: 'Icon',
        mixins: [componentsCore.mixins.skinBasedComp],
        getInitialState() {
            return {
                isIconClicked: false
            };
        },

        onClick() {
            //toggle behavior
            this.setState({isIconClicked: !this.state.isIconClicked});
        },

        getSkinProperties() {
            const compData = this.props.compData;
            const refData = {
                'img': {
                    parentConst: santaComponents.utils.createReactElement.bind(null, 'img'),
                    src: compData.url,
                    title: compData.title,
                    width: compData.width,
                    height: compData.height,
                    onClick: this.onClick
                }
            };
            refData[''] = {style: _.merge(this.props.style, {
                width: compData.width,
                height: compData.height
            })};
            return refData;
        }
    };

    componentsCore.compRegistrar.register('wixapps.integration.components.Icon', icon);

    return icon;
});
