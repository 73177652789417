define(['santa-components', 'lodash', 'wixappsCore/proxies/mixins/baseProxy', 'wixappsCore/core/proxyFactory', 'wixappsCore/core/styleTranslator'], function (santaComponents, _, baseProxy, /** wixappsCore.proxyFactory */proxyFactory, /** wixappsCore.styleTranslator */styleTranslator) {
    'use strict';

    const spacerCompSizeProp = {
        HSpacer: 'width',
        VSpacer: 'min-height'
    };

    const SPACER_COMP_NAMES = ['HSpacer', 'VSpacer'];

    function isSpacer(componentName) {
        return _.includes(SPACER_COMP_NAMES, componentName);
    }

    function getSpacerStyle(spacerViewDef, props) {
        const spacerStyle = this.getStyleDef(spacerViewDef, props);
        spacerStyle.boxSizing = 'border-box';
        const size = this.getCompProp('size', spacerViewDef);
        if (size === '*') {
            spacerStyle.boxFlex = 1;
        } else {
            const compName = this.getCompProp('name', spacerViewDef);
            spacerStyle[spacerCompSizeProp[compName]] = size;
        }
        return styleTranslator.translate(spacerStyle, props.orientation);
    }

    function getSpacerElement(spacerViewDef, props) {
        if (this.getCompProp('hidden', spacerViewDef)) {
            return null;
        }
        props.style = getSpacerStyle.call(this, spacerViewDef, props);
        return santaComponents.utils.createReactElement('div', props);
    }


    /**
     * @class proxies.mixins.baseComposite
     * @extends proxies.mixins.baseProxy
     * @property {proxy.properties} props
     */
    return {
        mixins: [baseProxy],

        renderChildProxy(childDef, key, childProxyStyle, props) {
            props = props || this.getChildProxyProps(childDef);
            key = String(key || '0');
            props.key = key; // eslint-disable-line santa/no-side-effects
            props.ref = key; // eslint-disable-line santa/no-side-effects
            props.refInParent = key; // eslint-disable-line santa/no-side-effects
            props.proxyLayout = childProxyStyle; // eslint-disable-line santa/no-side-effects

            if (this.getChildrenOrientation) {
                props.orientation = this.getChildrenOrientation();
            }

            const childCompName = this.getCompProp('name', childDef);

            if (isSpacer(childCompName)) {
                return getSpacerElement.call(this, childDef, props);
            }

            return proxyFactory.getProxyClass(childCompName)(props);
        }
    };
});
