define([
    'prop-types', 'santa-components'
], function (PropTypes, santaComponents) {
    'use strict';

    const {utils: santaUtils} = santaComponents;

    return santaUtils.createSantaTypesDefinitions({
        __DangerousSantaTypes: {
            getMeasures: PropTypes.func,
            forceUpdate: PropTypes.func
        },
        Data: {
            getDataByPath: PropTypes.func,
            setDataByPath: PropTypes.func,
            setBatchedData: PropTypes.func
        },
        packageName: PropTypes.string,
        descriptor: PropTypes.object,
        reportEvent: PropTypes.func,
        reportError: PropTypes.func,
        setPageTitle: PropTypes.func,
        appService: PropTypes.object,
        compExtraData: PropTypes.object,
        formatName: PropTypes.string,
        FunctionLibrary: PropTypes.func,
        localizationBundle: PropTypes.object,
        packageMetadata: PropTypes.object,
        Logic: PropTypes.func,
        getAppPageUrl: PropTypes.func,
        appPageParams: PropTypes.object,
        getAppPartZoomUrl: PropTypes.func,
        getComponentProps: PropTypes.func
    }, 'wixappsCoreSantaTypes');
});
