define([
    'lodash',
    'prop-types',
    'wixappsCore/proxies/mixins/baseCompositeProxy'
], function (_, PropTypes, baseCompositeProxy) {
    'use strict';

    /**
     * @class proxies.SwitchBox
     * @extends proxies.mixins.baseComposite
     */
    return {
        mixins: [baseCompositeProxy],
        propTypes: {
            viewDef: PropTypes.object.isRequired
        },
        renderProxy() {
            const cases = this.getCompProp('cases');
            const data = _.has(cases, this.proxyData) ? this.proxyData : 'default';
            const itemsDefinitions = cases[data];
            if (!itemsDefinitions || itemsDefinitions.length === 0) {
                return null;
            }

            const orientation = this.getCompProp('orientation') || 'vertical';
            const childViewDef = _.cloneDeep(_.omit(this.props.viewDef, ['comp.cases']));

            // cleanup
            delete childViewDef.data;
            delete childViewDef.name;
            delete childViewDef.forType;
            delete childViewDef.comp.cases;

            // build
            childViewDef.id = `${childViewDef.id}_${data}`;
            childViewDef.comp.name = orientation === 'vertical' ? 'VBox' : 'HBox';
            childViewDef.comp.items = itemsDefinitions;

            return this.renderChildProxy(childViewDef, childViewDef.id, this.getProxyStyle());
        }
    };
});
