define([
    'lodash',
    'coreUtils',
    'santa-components'
], function (_, coreUtils, santaComponents) {
    'use strict';

    const {createComponentSantaTypeFetcher, createComponentSantaType, createGlobalSantaType} = coreUtils.propsSelectorsUtils;
    const {santaTypesDefinitions} = santaComponents;
    function getCompData(state, props) {
        const compData = state.fetchSantaType(santaTypesDefinitions.Component.compData, state, props);
        return compData.dataItemRef || compData;
    }

    function getPackageName(state, props) {
        const compData = getCompData(state, props);
        const appService = state.siteData.getClientSpecMapEntry(compData.appInnerID);
        return appService && (appService.packageName || appService.type);
    }

    function touchRenderCounter(state, props) {
        const packageName = getPackageName(state, props);
        const displayDal = state.siteAPI.getDisplayedDAL();
        const pointers = state.siteAPI.getPointers();
        const compData = state.fetchSantaType(santaTypesDefinitions.Component.compData, state, props);

        displayDal.get(pointers.wixapps.getCounterData(packageName));
        displayDal.get(pointers.wixapps.getCounterData(packageName, props.id));
        displayDal.get(pointers.wixapps.getCounterData(packageName, compData.id));
    }

    function fetchAndTouch(fetcher, stopUpdateWhenReady) {
        return function (state, props) {
            const result = fetcher(state, props);
            if (stopUpdateWhenReady && result) {
                return result;
            }
            touchRenderCounter(state, props);
            return _.clone(result);
        };
    }

    function createWixappsSantaType(propType, fetcher, stopUpdateWhenReady) {
        return createComponentSantaType(propType, fetchAndTouch(fetcher, stopUpdateWhenReady));
    }

    function createWixappsComponentFetcher(fetcher, stopUpdateWhenReady) {
        return createComponentSantaTypeFetcher(fetchAndTouch(fetcher, stopUpdateWhenReady));
    }

    function createGlobalWixappsSantaType(propType, fetcher, name) {
        return createGlobalSantaType(propType, fetchAndTouch(fetcher), name);
    }

    return {
        createGlobalWixappsSantaType,
        createWixappsComponentFetcher,
        createWixappsSantaType,
        getPackageName,
        getCompData
    };
});
