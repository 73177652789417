define(['santa-components', 'lodash', 'wixappsCore/proxies/mixins/baseCompositeProxy'], function (santaComponents, _, baseCompositeProxy) {
    'use strict';

    /**
     * @class proxies.Css
     * @extends proxies.mixins.baseComposite
     */
    return {
        mixins: [baseCompositeProxy],

        renderProxy() {
            const childrenDefinitions = this.getCompProp('items');

            const children = _.map(childrenDefinitions, function (childDef, i) {
                return this.renderChildProxy(childDef, i);
            }.bind(this));

            const props = this.getChildCompProps();

            return santaComponents.utils.createReactElement('div', props, children);
        }
    };
});
