define(['lodash', 'skins', 'coreUtils', 'wixappsCore/util/viewsUtils', 'wixappsCore/core/styleData', 'wixappsCore/core/styleMap.json'], function (_, skinsPackage, coreUtils, viewsUtils, styleData, styleMap) {
    'use strict';

    const skinsMap = skinsPackage.skinsMap;

    /**
     * Adds a the style if it wasn't loaded already
     * @param {string} styleId The style Id
     * @param {string} skinName The name of the skin
     * @param {data.themeData} themeData
     * @param {{skinName: string, componentType: string}} loadedStyles
     */
    function addStyleIfNeeded(styleId, skinName, getStyleData, loadedStyles) { // eslint-disable-line complexity
        styleId = _.isString(styleId) ? styleId : null;
        const styleDef = styleId && getStyleData(styleId) && skinsMap.get(getStyleData(styleId).skin);
        let key;
        if (styleDef) {
            key = styleId;
        } else {
            const skinDef = skinName && skinsMap.get(skinName);
            key = skinDef ? coreUtils.santaTypes.shortenStyleId(skinName) : '';
        }
        if (key && !loadedStyles[key]) {
            loadedStyles[key] = {
                skinName
            };
        }
    }

    /**
     * Collect all the styles that are in use in this view definition and add it to the loadedStyles
     * @param {object} viewDef The view definition
     * @param {data.themeData} themeData
     * @param {{skinName: string, componentType: string}} loadedStyles
     */
    function collectViewStyles(viewDef, getStyleData, loadedStyles) {
        function processViewDef(currViewDef) {
            const styleId = _.get(currViewDef, 'comp.style');
            const skinName = _.get(currViewDef, 'comp.skin') || currViewDef.comp && styleData.getDefaultSkinName(currViewDef.comp.name); // eslint-disable-line no-mixed-operators

            addStyleIfNeeded(styleId, skinName, getStyleData, loadedStyles);
        }

        viewsUtils.traverseViews(viewDef, processViewDef);
    }

    /**
     * Adds all the default styles that are set in StyleData
     * @param {data.themeData} themeData
     * @param {{skinName: string, componentType: string}} loadedStyles
     */
    function addDefaultStyles(getStyleData, loadedStyles) {
        _.forEach(styleMap, function (compStyle) {
            _.forEach(compStyle, function (styleObj) {
                addStyleIfNeeded(styleObj.style, styleObj.skin, getStyleData, loadedStyles);
            });
        });
    }

    /**
     * @class wixappsCore.styleCollector
     */
    return {
        collectViewStyles,
        addStyleIfNeeded,
        addDefaultStyles
    };
});
