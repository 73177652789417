define(['santa-components', 'lodash', 'wixappsCore/proxies/mixins/baseCompositeProxy'], function (santaComponents, _, baseCompositeProxy) {
    'use strict';

    /**
     * @class proxies.Stack
     * @extends proxies.mixins.baseCompositeProxy
     */
    return {
        mixins: [baseCompositeProxy],

        getCustomStyle() {
            return {
                position: 'relative'
            };
        },

        renderProxy() {
            const childrenDefinitions = this.getCompProp('items');

            const children = _.map(childrenDefinitions, function (childDef, i) {
                const childLayoutDef = this.getCompProp('layout', childDef);
                const positionProp = i === 0 && this.getCompProp('stackType') !== 'layout' ? 'static' : 'absolute';
                const childLayout = {
                    position: positionProp,
                    top: childLayoutDef.top || '0px',
                    left: childLayoutDef.left || '0px'
                };

                return this.renderChildProxy(childDef, i, childLayout);
            }.bind(this));

            const props = this.getChildCompProps();
            props['data-proxy-name'] = 'Stack';

            return santaComponents.utils.createReactElement('div', props, children);
        }
    };
});
